import { main as deferredFeatures } from "app/DeferredFeatures";

const eventBus = global["__EVENT_BUS__"];
const resolve = global["__DEFERRED_FEATURES_P_RESOLVE_FUNC__"];
const reject = global["__DEFERRED_FEATURES_P_REJECT_FUNC__"];

async function main() {
  try {
    await deferredFeatures({ eventBus });
    resolve();
  } catch (e) {
    console.error({ e });
    reject(e);
    // send e somewhere
  }
}

main();
